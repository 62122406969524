/* eslint-disable import/prefer-default-export */
/*
 * src/@context/PlatformMobile.tsx
 *
 * This file contains a provider for the mobile platform. The context is in a
 * separate file so that we can import it to check for the existance of this
 * provider without importing everything that the provider requires.
 */
import React, {
	useEffect,
	useMemo,
	FC,
	useState,
	useRef,
	useCallback,
} from 'react';
import {
	ActionPerformed,
	PushNotifications,
	Token,
} from '@capacitor/push-notifications';
import PlatformMobileContext, {
	PlatformMobile,
	PushNotificationActionPerformedCallback,
} from './PlatformMobileContext';

type PlatformMobileProviderProps = {
	children: React.ReactNode;
};

export const PlatformMobileProvider: FC<PlatformMobileProviderProps> = ({
	children,
}) => {
	const [pushNotificationToken, setPushNotificationToken] = useState('');
	const pushNotificationActionPerformedCallbacksRef = useRef<
		PushNotificationActionPerformedCallback[]
	>([]);

	useEffect(() => {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermissions().then((result) => {
			if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				PushNotifications.register();
			} else {
				// Show some error
			}
		});

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration', (token: Token) => {
			setPushNotificationToken(token.value);
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener('registrationError', (error) => {
			// eslint-disable-next-line no-console
			console.error(`Error registerung push notifications:`);
			// eslint-disable-next-line no-console
			console.error(error);
		});

		// Method called when tapping on a notification
		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			(notification: ActionPerformed) => {
				// eslint-disable-next-line no-console
				const callbacks = pushNotificationActionPerformedCallbacksRef.current;

				callbacks.forEach((callback) => {
					callback(notification);
				});
			}
		);
	}, []);

	const onPushNotificationActionPerformed = useCallback(
		(callback: PushNotificationActionPerformedCallback) => {
			const callbacks = pushNotificationActionPerformedCallbacksRef.current;
			callbacks.push(callback);

			const unsubscribe = () => {
				const index = callbacks.indexOf(callback);
				if (index > -1) {
					callbacks.splice(index, 1);
				}
			};

			return unsubscribe;
		},
		[]
	);

	// Fix relative links on mobile that aren't opening correctly
	useEffect(() => {
		const onClick = (e:MouseEvent) => {
			const {target} = e;

			if (!(target instanceof HTMLAnchorElement)) {
				return
			}

			const href = target.getAttribute('href') // Don't use target.href because that is automatically prefixed with the current URL
			if (target.target === '_blank' && href?.startsWith('/')) {
				e.preventDefault();

				window.open(process.env.REACT_APP_AUTH_URL + href)
			}
		}

		document.addEventListener('click', onClick)

		return () => {
		document.removeEventListener('click', onClick)
		}
	}, [])

	const contextValue = useMemo<PlatformMobile>(
		() => ({
			isActive: true,
			platform: 'IOS',
			pushNotificationToken,
			onPushNotificationActionPerformed,
		}),
		[onPushNotificationActionPerformed, pushNotificationToken]
	);

	return (
		<PlatformMobileContext.Provider value={contextValue}>
			{children}
		</PlatformMobileContext.Provider>
	);
};
